import React, { useState, useEffect } from 'react'
import { Layout } from 'components'
import RegisterForm from './form'
import { 
  Title, 
  Li, 
  Ul, 
  LeftContainer, 
  Container,
  RightContainer 
} from './styles'
import { FormattedMessage } from 'utils/intl'
import API from 'commons/API'

const LEFT_CONTENTS = [
  {
    subtitle : <FormattedMessage id="87b60309e" />,
    desc     : <FormattedMessage id="dde7c9152" />
  },
  {
    subtitle : <FormattedMessage id="fa817c5ed" />,
    desc     : <FormattedMessage id="936fa5cfa" />
  },
  {
    subtitle : <FormattedMessage id="ba7c57d22" />,
    desc     : <FormattedMessage id="7528dd4a5" />
  },
  {
    subtitle : <FormattedMessage id="01413cfe6" />,
    desc     : <FormattedMessage id="fd07b01dc" />
  },
  {
    subtitle : <FormattedMessage id="645f999db" />,
    desc     : <FormattedMessage id="cbe03e289" />
  }
]

const RegisterLayout = (props) => {
  const [defaultChoosen, setDefaultChoosen] = useState('')

  useEffect(() => {
    const getDefaultChoosen = async () => {
      try {
        const { data: ipUser } = await API('https://api.ipify.org/?format=json')
        const { data: dataLocation } = await API(`https://ipapi.co/${ipUser.ip}/json/`)
        setDefaultChoosen(dataLocation.country)
      } catch (err) {
        console.log('--- error getting current location', err.message)
      }
    }
    getDefaultChoosen()
  }, [])
  return (
    <Layout>
      <Container>
        <LeftContainer>
          <Title>BENEFITS</Title>
          <Ul>
            { LEFT_CONTENTS.map((content, i) => (
              <Li key={ i.toString() }>
                <Li.Heading>{ content.subtitle }</Li.Heading>
                <Li.Desc>{ content.desc }</Li.Desc>
              </Li>
            )) }
          </Ul>
        </LeftContainer>
        <RightContainer>
          <RegisterForm defaultChoosen={ defaultChoosen } { ...props } />
        </RightContainer>
      </Container>
    </Layout>
  )}

export default RegisterLayout
