import styled from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  padding: 3em 8rem;
  background-color: #fff;
  background-image: url('/eye-illustration.png');
  background-repeat: no-repeat;
  background-size: 93%;
  background-position: center;
  margin: auto;

  @media only screen and (min-width: 1024px) and (max-width: 1050px) { 
    padding: 3rem 5rem;
  }

  ${IS_FRAME_TABLET} {
    display: flex;
    padding: 30px 40px 50px 40px;
    width: unset;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
    padding: 3em 1.5rem;
    width: unset;
  }
`

export const Title = styled.div`
  font-size: 3em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: ${darkBlueGrey};
  margin-bottom: 8px;
`

export const LeftContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  align-content: center;

  ${IS_FRAME_MOBILE} {
    display: none;
  }

  ${IS_FRAME_TABLET} {
    display: none;
  }
`

export const WordingContainer = styled.div`
  position: absolute;
  bottom: 0;
  img {
    width: 90%;
    height: 375px;
    opacity: 0.7;
  }
  ${IS_FRAME_MOBILE} {
    position: static;
    img {
      display: none;
    }
  }
`

export const LogoContainer = styled.div`
  padding-left: 45px;
  margin-top: 5vh;
  margin-bottom: 13px;
  @media only screen and (max-width: 600px) {
    padding-left: 0px;
  }
`

export const RightContainer = styled.div`
  width: 76%;
  margin-left: auto;

  ${IS_FRAME_TABLET} {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0;
  }

  ${IS_FRAME_MOBILE} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
`

export const ListContent = styled.div`
  position: absolute;
  top: 0;

  ${IS_FRAME_MOBILE} {
    position: unset;
    text-align: center;
  }
`

export const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-row-gap: 1.5em;
`

export const Li = styled.li`
  display: grid;
`

Li.Heading = styled.h5`
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: ${darkBlueGrey};
  margin: 0;
`

Li.Desc = styled.p`
  margin: 0;
  font-size: 1.3em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: ${darkBlueGrey};
`
