import React from "react";

import Register from "domains/Register";
import SEO from "components/seo";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  .grecaptcha-badge {
    bottom: 87px !important;
  }
`;

const RegisterPage = (props) => {
  return (
    <>
      <SEO
        title="Register Free Trial"
        description="Experience all of our solutions in a single click. Get your free trial now!"
      />
      <Register {...props} />
      <GlobalStyle />
    </>
  );
};

export default RegisterPage;
